import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Header, Segment} from "semantic-ui-react";

function App() {
    const [admins, setAdmins] = useState<Array<string>>([""]);
    const [admin, setAdmin] = useState<string | undefined>();
    const [totalAmount, setTotalAmount] = useState<number | undefined>();
    const [cashAmount, setCashAmount] = useState<number | undefined>();
    const [cardAmount, setCardAmount] = useState<number | undefined>();
    const [salary, setSalary] = useState<number | undefined>();
    const [hoursAmount, setHoursAmount] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [isSuccess, setIsSuccess] = useState(false);
    const hasEmptyFields: boolean = admin === "" || totalAmount === undefined
        || cashAmount === undefined || cardAmount === undefined
        || salary === undefined || hoursAmount === undefined;

    useEffect(() => {
        void loadAdmins();
    }, [])

    async function loadAdmins() {
        const request = await fetch("/api/Report/getAdmins");
        const response = await request.json();
        if (response.success) {
            setAdmins(response.data);
            setError(undefined);
        } else {
            setError(response.error);
            setIsSuccess(false);
        }
    }

    async function addReport() {
        setIsSuccess(false);
        setIsLoading(true);
        const request = await fetch('/api/Report/addReport', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                adminName: admin,
                totalAmount: totalAmount,
                cashAmount: cashAmount,
                cardAmount: cardAmount,
                salary: salary,
                hoursAmount: hoursAmount
            })
        });
        const response = await request.json();
        console.log(response);
        if (response.success) {
            clear();
            setIsSuccess(true);
            setError(undefined);
        } else {
            setError(response.error);
            setIsSuccess(false);
        }
        setIsLoading(false);
    }

    function clear() {
        setAdmin(undefined);
        setTotalAmount(undefined);
        setCashAmount(undefined);
        setCardAmount(undefined);
        setSalary(undefined);
        setHoursAmount(undefined);
    }

    return (
        <Container text textAlign={"center"} style={{marginTop: "25px"}}>
            <Segment color={'teal'} loading={isLoading}>
                <Form onSubmit={() => addReport()}>
                    <Form.Select
                        label={"Админ"}
                        value={admin ?? ''}
                        onChange={(_, e) => setAdmin(e.value as string ?? "")}
                        options={admins.map(adminName => {
                            return {
                                key: adminName,
                                value: adminName,
                                text: adminName
                            }
                        })}
                    />
                    <Form.Group widths={"equal"}>
                        <Form.Input
                            value={totalAmount ?? ''}
                            onChange={(_, e) => setTotalAmount(e.value ? +e.value : undefined)}
                            type={"number"}
                            label={"Всего"}
                        />
                        <Form.Input
                            value={cashAmount ?? ''}
                            onChange={(_, e) => setCashAmount(e.value ? +e.value : undefined)}
                            type={"number"}
                            label={"Наличка"}
                        />
                        <Form.Input
                            value={cardAmount ?? ''}
                            onChange={(_, e) => setCardAmount(e.value ? +e.value : undefined)}
                            type={"number"}
                            label={"Безнал"}
                        />
                    </Form.Group>
                    <Form.Group widths={"equal"}>
                        <Form.Input
                            value={salary ?? ''}
                            onChange={(_, e) => setSalary(e.value ? +e.value : undefined)}
                            type={"number"}
                            label={"Зарплата"}
                        />
                        <Form.Input
                            value={hoursAmount ?? ''}
                            onChange={(_, e) => setHoursAmount(e.value ? +e.value : undefined)}
                            type={"number"}
                            label={"Часы"}
                        />
                    </Form.Group>
                    <Button disabled={hasEmptyFields} fluid primary>Отправить</Button>
                </Form>
            </Segment>
            {isSuccess && <Segment color={'teal'} onClick={() => setIsSuccess(false)}>
                Отправлено успешно!
            </Segment>}
            {error && <Segment color={'red'}>
                <Header color={'red'}>Ошибка</Header>
                {error}
            </Segment>}
        </Container>
    );
}

export default App;
